export default function equalHeighInit() {
  const $win = $(window);

  $.fn.equalizeHeight = function () {
    let maxHeight = 0;
    let itemHeight;

    this.height('');

    for (let i = 0; i < this.length; i++) {
      itemHeight = $(this[i]).height('auto').height();
      if (maxHeight < itemHeight) {
        maxHeight = itemHeight;
      }
    }

    return this.height(maxHeight);
  };

  $win.on('load resize', () => {
    $('.block-information').equalizeHeight();

    $('.card .card__content').equalizeHeight();

    $('.article').equalizeHeight();

    $('.widget-feature .widget__image').equalizeHeight();
  });
}
